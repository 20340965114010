<template>
  <h1 style="color: white">Home, sweet home...</h1>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
