import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./style.css";
import TelegramAuthButton from "@/components/TelegramAuthButton";

let app = createApp(App);
app.component("telegram-auth-button", TelegramAuthButton);
app.use(router).mount("#app");
