<template>
  <div class="full-center login-form">
    <h3>Sign in</h3>

    <div class="full-center">
      <telegram-auth-button
        mode="redirect"
        :redirect-url="redirect_dashboard_url"
        telegram-login="craffbot"
        @callback="telegramLoginCallback"
      ></telegram-auth-button>
    </div>
  </div>
</template>

<script>
import TelegramAuthButton from "@/components/TelegramAuthButton";
import { config } from "@/config";

export default {
  name: "LoginView",
  data() {
    return {
      redirect_dashboard_url: config.redirect_dashboard_url,
    };
  },
  components: {
    TelegramAuthButton,
  },
  methods: {
    telegramLoginCallback(user) {
      alert(JSON.stringify(user));
    },
  },
};
</script>

<style scoped>
.login-form {
  width: 35%;
  height: 20%;
  background-color: #171d26;
  border-radius: 25px;

  text-align: center;
}

h3 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 30px;

  margin: 15px;
}
</style>
