<template>
  <header>
    <img src="./assets/logo.png" alt="" />
    <a style="padding-left: 30px" href="/">Craff</a>

    <nav>
      <router-link to="/login">Account</router-link>
      <router-link to="/support">Support</router-link>
      <router-link to="/faq">FAQ</router-link>
    </nav>
  </header>
  <router-view />
</template>

<style>
body {
  background-color: #111926;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  padding: 15px 15px 15px 30px;
  display: flex;
  align-items: center;
}

nav {
  margin-right: 50px;
  position: absolute;
  right: 0;
}

nav a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  font-weight: 400 !important;
  font-size: 20px !important;
}

nav a:hover {
  color: #70afee;
  transition: color 0.3s;
}

header a {
  padding: 15px;
  color: #ffffff;
  text-decoration: none;
  font-size: 24px;

  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

nav a.router-link-exact-active {
  color: #22449f;
}
</style>
